import youtubeLogo from "./assets/youtube1.png";
import instagramLogo from "./assets/instagram1.png";
import pinterestLogo from "./assets/pinterest1.png";
import facebookLogo from "./assets/facebook1.png";
import "./App.css";
import Box from "./components/boxes/Box";
import Profile from "./components/profile/Profile";

function App() {
  const appContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 50,
  };

  const profileStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const boxRowStyle = {
    display: "flex",
    marginTop: 10,
  };

  const boxesContainerStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: 50,
  };

  const emailStyle = {
    fontWeight: "bold",
  };

  const emailLabel = {
    display: "flex",
  };

  const emailContainer = {
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  };

  return (
    <div style={appContainer}>
      <div style={profileStyle}>
        <Profile />
      </div>

      <div style={boxesContainerStyle}>
        <div style={boxRowStyle}>
          <Box
            icon={youtubeLogo}
            link="https://www.youtube.com/c/VizoliArt"
            alt="YouTube"
          />
          <Box
            icon={instagramLogo}
            link="https://www.instagram.com/vizoliart"
            alt="Instagram"
          />
        </div>

        <div style={boxRowStyle}>
          <Box
            icon={pinterestLogo}
            link="https://www.pinterest.de/vizoliart"
            alt="Pinterest"
          />
          <Box
            icon={facebookLogo}
            link="https://www.facebook.com/vizoliart"
            alt="Facebook"
          />
        </div>

        <div style={emailContainer}>
          <span style={emailLabel}>Contact:</span>
          <span style={emailStyle}>info@vizoliart.com</span>
        </div>
      </div>
    </div>
  );
}

export default App;
