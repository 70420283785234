import { Paper } from "@material-ui/core";

function Box({ icon, link, alt }) {
  const container = {
    marginLeft: 5,
    marginRight: 5,
    width: 150,
    height: 150,
  };

  return (
    <Paper style={container} elevation={1} onmo>
      <a href={link} target="_blank" rel="noreferrer">
        <img src={icon} alt={alt} width="150" />
      </a>
    </Paper>
  );
}

export default Box;
